import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  // {
  //   path: '/more',
  //   name: 'more',
  //   component: () => import('../views/more.vue')
  // },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  }
  // {
  //   path: '/game',
  //   name: 'game',
  //   component: () => import('../views/game.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
